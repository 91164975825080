import * as d3 from "d3";
import React, { useEffect, useRef, useCallback } from "react";
import "../../assets/css/graphicsComponents/modelsRepresentations/kmeansd3.css";

export const KmeansD3 = ({ result, fieldList, setSelectedCluster }) => {
   const d3Chart = useRef();

   // Define a cor do cluster com base no índice
   const KmeansKPI = useCallback((d, i) => {
      const colorScheme = [
         "rgba(73, 47, 117, 1)",
         "rgba(38, 55, 128, 1)",
         "rgba(2, 62, 138, 1)",
         "rgba(105, 61, 143, 1)",
         "rgba(53, 106, 171, 1)",
         "rgba(0, 150, 199, 1)",
         "rgba(149, 101, 178, 1)",
         "rgba(111, 152, 203, 1)",
         "rgba(72, 202, 228, 1)",
         "rgba(205, 167, 224, 1)",
         "rgba(189, 200, 234, 1)",
         "rgba(173, 232, 244, 1)",
      ];
      return colorScheme[i % colorScheme.length];
   }, []);

   // Função que cria e atualiza o tooltip
   function showTooltip(event, cluster) {
      const tooltip = d3.select(".d3-tooltip");
      tooltip
         .style("opacity", 1)
         .html(
            `
        <div style="padding: 8px; font-size: 14px; color: white;">
          <h3 style="margin: 0 0 4px;">${cluster.name}</h3>
          <div><strong>Count: ${cluster.count}</strong></div>
          <div><strong>Percentage: ${(cluster.percentage * 100).toFixed(2)}%</strong></div>
        </div>
      `,
         )
         .style("left", `${event.pageX + 15}px`)
         .style("top", `${event.pageY + 15}px`)
         .style("background", "rgba(0, 0, 0, 0.75)")
         .style("border-radius", "8px")
         .style("box-shadow", "0px 4px 12px rgba(0, 0, 0, 0.2)")
         .style("pointer-events", "none")
         .style("white-space", "nowrap");
   }

   function hideTooltip() {
      d3.select(".d3-tooltip").style("opacity", 0);
   }

   const desenharKmeans = useCallback(() => {
      if (!result || !result.hasOwnProperty("centers")) return;

      d3.select("#kmeansd3-circle").remove();

      const margin = { top: 0, right: 0, bottom: 0, left: 0 };
      const width = parseInt(d3.select(".model-result-1").style("width"), 10) - margin.left - margin.right;
      const height = parseInt(d3.select(".model-result-1").style("height"), 10) - margin.top - margin.bottom;

      const svg = d3
         .select(d3Chart.current)
         .attr("viewBox", `0 0 ${width} ${height}`)
         .attr("preserveAspectRatio", "xMidYMid meet")
         .append("g")
         .attr("transform", `translate(${margin.left},${margin.top})`);

      const escala = d3
         .scaleLinear()
         .domain([0, Math.max(...result.centers.map((x) => x.count))])
         .range([10, width / 10]);

      const node = svg
         .append("g")
         .attr("id", "kmeansd3-circle")
         .attr("class", "nodes")
         .selectAll("circle")
         .data(result.centers)
         .join("circle")
         .attr("cx", ({ x }) => x)
         .attr("cy", ({ y }) => y)
         .attr("r", (d) => escala(d.count))
         .attr("fill", (d, i) => KmeansKPI(d, i))
         .on("mouseover", (event, cluster) => {
            setSelectedCluster(cluster);
            showTooltip(event, cluster);
            d3.select(event.currentTarget).style("stroke", "lightgray").style("stroke-width", "2px");
         })
         .on("mouseout", (event) => {
            hideTooltip();
            d3.select(event.currentTarget).style("stroke", "none");
         });

      d3.forceSimulation(result.centers)
         .force("center", d3.forceCenter(width / 2, height / 2))
         .force(
            "collide",
            d3
               .forceCollide()
               .strength(0.7)
               .radius((d) => escala(d.count))
               .iterations(3),
         )
         .on("tick", () => {
            node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
         });
   }, [KmeansKPI, result, setSelectedCluster]);

   useEffect(() => {
      // Adiciona o tooltip ao carregar o componente
      d3.select("body")
         .append("div")
         .attr("class", "d3-tooltip")
         .style("position", "absolute")
         .style("background-color", "black")
         .style("color", "white")
         .style("padding", "5px")
         .style("border-radius", "5px")
         .style("opacity", 0);

      desenharKmeans();

      return () => {
         d3.select(".d3-tooltip").remove();
      };
   }, [desenharKmeans, fieldList, result]); // remover fieldList e result

   return <svg ref={d3Chart} id="kmeansd3-svg" />;
};
