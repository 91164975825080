import { toast } from "react-toastify";

/**
 * Handles the error response from the backend API.
 *
 * @param {object} history - The history object from react-router-dom.
 * @param {object} error - The error object received from the API response.
 * @param {function} t - The translation function for localization.
 */
function handleErrorResponse(history, error, t) {
   if (error?.message === "Network Error") {
      toast.error(t("backend.ERROR._ERROR_500"));
   } else if (error?.error) {
      const type = error.error.includes("_ALERT_") ? "ALERT" : "ERROR";
      toast.error(t(`backend.${type}.${error.error}`));
   } else if (error?.response?.status === 401) {
      const savedLanguage = localStorage.getItem("selectedLanguage");
      localStorage.clear();
      if (savedLanguage) {
         localStorage.setItem("selectedLanguage", savedLanguage);
      }
      history.push("/");
      toast.error(t("backend.ERROR._ERROR_18"));
   } else if (error?.response?.data && error.response.data !== "_ERROR_112") {
      const type = error.response.data.includes("_ALERT_") ? "ALERT" : "ERROR";
      toast.error(t(`backend.${type}.${error.response.data}`));
   } else {
      toast.error(t("backend.ERROR._UNKNOWN_ERROR"));
   }
}

export default handleErrorResponse;
