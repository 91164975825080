import "./../../../assets/css/views/popups/creations/choosealgorithmpopup.css";
import DecisionTreeIcon from "./ChooseAlgorithmPopUp/assets/icons/jsxIcons/DecisionTreeIcon";
import RandomForestIcon from "./ChooseAlgorithmPopUp/assets/icons/jsxIcons/RandomForestIcon";
import LinearRegressionIcon from "./ChooseAlgorithmPopUp/assets/icons/jsxIcons/LinearRegressionIcon";
import SVMIcon from "../../../assets/icons/jsxIcons/SVMIcon";
import XGBoostIcon from "./ChooseAlgorithmPopUp/assets/icons/jsxIcons/XGBoostIcon";
import MultilayerPerceptronIcon from "../../../assets/icons/jsxIcons/MultilayerPerceptronIcon";
import KmeansIcon from "./ChooseAlgorithmPopUp/assets/icons/jsxIcons/KmeansIcon";
import TableIcon from "../../../assets/icons/jsxIcons/TableIcon";
import AnomaliesIcon from "../../../assets/icons/jsxIcons/AnomaliesIcon";
import AssociationDiscoveryIcon from "../../../assets/icons/jsxIcons/AssociationDiscoveryIcon";
import FileIcon from "../../../assets/icons/jsxIcons/FileIcon";
import AlgorithmDatasetIcon from "./ChooseAlgorithmPopUp/assets/icons/jsxIcons/AlgorithmDatasetIcon";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import { selectMainAttribute, setMainAttribute } from "../../../store/slices/mainAttribute/mainAttributeSlice";
import { HistogramPopup } from "../informations/HistogramPopup";
import { Popup } from "../../../components/Popup";
import { useSelector, useDispatch } from "react-redux";

import { useState, useEffect } from "react";
import FPGrowthD3Example from "../../../graphicsComponents/examples/FPGrowthD3Example";
import { Info, WarningOutlined } from "@material-ui/icons";
import HowToDialog from "../../../components/HowTo/HowToDialog";
import ChooseAlgTour from "../../../components/Wizard/ChooseAlgTour";
import { selectTutorialDone } from "../../../store/slices/wizard/wizardSlice";
import { Checkbox } from "semantic-ui-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "../../../components/Button";
import Section from "./ChooseAlgorithmPopUp/components/Section";
import { modelComponents } from "./ChooseAlgorithmPopUp/components/modelComponents";

function ChooseAlgorithmPopup({ dataset, setTrigger }) {
   const { name: datasetName, projectName, id: datasetId, headerInfo: tableData } = dataset;
   const { t } = useTranslation();
   const link = "popup.creations.choosealgorithm";
   const mainAttribute = useSelector(selectMainAttribute);
   const tutorialDone = useSelector(selectTutorialDone);
   const dispatch = useDispatch();
   const history = useHistory();

   const [selected, setSelected] = useState("supervised");
   const [nameAlgorithm, setNameAlgorithm] = useState("Decision Tree");
   const [nameModel, setNameModel] = useState("");
   const [histogramPopUp, setHistogramPopUp] = useState(false);
   const [listTarget, setListTarget] = useState([]);

   const [showTarget, setShowTarget] = useState(true);
   const [equalColumns, setEqualColumns] = useState(false);
   const [openDialog, setOpenDialog] = useState(false);

   const mapData = (data) => ({ name: data.Nome, selected: true });

   const listNumeric = [];
   const listCategorical = [];
   const listNumericAndCategorical = [];

   tableData.forEach((element) => {
      const tipo = element.Tipo.toLowerCase();
      if (tipo === "numeric") {
         listNumeric.push(mapData(element));
         listNumericAndCategorical.push(mapData(element));
      } else if (tipo === "categorical") {
         listCategorical.push(mapData(element));
         listNumericAndCategorical.push(mapData(element));
      }
   });

   const [listData, setListData] = useState([]);

   const [attributesALS, setAttributesALS] = useState({
      userColumn: 0,
      productColumn: 1,
      ratingsColumn: 2,
   });

   useEffect(() => {
      //filtra a lista de atributos e a lista de target para cada modelos
      const name = nameAlgorithm.toLowerCase();
      if (
         name === "linear regression" ||
         name === "support vector machine" ||
         name === "multilayer perceptron" ||
         name === "als"
      ) {
         setListTarget(name === "linear regression" || name === "als" ? listNumeric : listCategorical);
         setListData(listNumeric);
         dispatch(setMainAttribute(name === "linear regression" ? listNumeric[0]?.name : listCategorical[0]?.name));
      } else {
         setListTarget(listNumericAndCategorical);
         setListData(listNumericAndCategorical);
         dispatch(setMainAttribute(listNumericAndCategorical[0].name));
      }
   }, [nameAlgorithm]);

   function handleDisplay(id, state = "") {
      const element = document.getElementById(id);
      if (element) {
         if (state === "") {
            element.style.display = element.style.display === "flex" ? "none" : "flex";
         } else {
            element.style.display = state;
         }
      }
   }

   function handleSelected(attribute) {
      setListData(
         listData.map((data) => {
            if (data.name === attribute.name && data.name !== mainAttribute)
               return { ...data, selected: !data.selected };
            return data;
         }),
      );
   }

   const handleClickCreate = (e) => {
      if (mainAttribute === "" || mainAttribute === null) {
         e.preventDefault();
      } else {
         setTrigger(false);
         history.push({
            pathname: "/model/create",
            state: {
               nameAlgorithm: nameAlgorithm,
               selectedDataset: datasetName,
               nameModel: nameModel,
               datasetName: datasetName,
               datasetId: datasetId,
               projectName: projectName,
               tableDataActive: listData.filter((data) => data.selected),
               mainAttribute: mainAttribute,
               attributesALS: attributesALS,
            },
         });
      }
   };

   const chooseAlgorithm = () => {
      const supervisedAlgorithms = [
         { name: "Decision Tree", icon: DecisionTreeIcon },
         { name: "Random Forest", icon: RandomForestIcon },
         { name: "Linear Regression", icon: LinearRegressionIcon },
         { name: "Gradient Boosting", icon: XGBoostIcon },
         { name: "Support Vector Machine", icon: SVMIcon },
         { name: "Multilayer Perceptron", icon: MultilayerPerceptronIcon },
      ];

      const unsupervisedAlgorithms = [
         { name: "Cluster", icon: KmeansIcon },
         { name: "ALS", icon: TableIcon },
         { name: "Isolation Forest", icon: AnomaliesIcon },
         { name: "FPGrowth", icon: AssociationDiscoveryIcon },
      ];

      return (
         <>
            <Section
               sectionName="supervised"
               algorithms={supervisedAlgorithms}
               displayId="supervised"
               typeSelected={selected}
               toggleSelection={() => {
                  handleDisplay("unsupervised", "none");
                  handleDisplay("supervised");
                  setSelected(selected === "supervised" ? "" : "supervised");
               }}
               link={link}
               setShowTarget={setShowTarget}
               nameAlgorithm={nameAlgorithm}
               setNameAlgorithm={setNameAlgorithm}
            />
            <Section
               sectionName="unsupervised"
               algorithms={unsupervisedAlgorithms}
               displayId="unsupervised"
               typeSelected={selected}
               toggleSelection={() => {
                  handleDisplay("supervised", "none");
                  handleDisplay("unsupervised");
                  setSelected(selected === "unsupervised" ? "" : "unsupervised");
               }}
               link={link}
               setShowTarget={setShowTarget}
               nameAlgorithm={nameAlgorithm}
               setNameAlgorithm={setNameAlgorithm}
            />
         </>
      );
   };

   const modelRepresentation = () => {
      const name = nameAlgorithm.toLowerCase();
      return modelComponents[name] || <FPGrowthD3Example />;
   };

   const renderInfo = () => {
      const linkInfo = `${link}.algorithms.content`;
      const infoKeys = {
         "Decision Tree": "decisiontree",
         "Random Forest": "randomforest",
         "Linear Regression": "linearregression",
         "Gradient Boosting": "gradientboosting",
         "Support Vector Machine": "svm",
         "Multilayer Perceptron": "multilayerperceptron",
         Cluster: "cluster",
         ALS: "als",
         "Isolation Forest": "isolationforest",
         FPGrowth: "fpgrowth",
      };

      const infoKey = infoKeys[nameAlgorithm];
      return infoKey ? t(`${linkInfo}.${infoKey}.info`) : "";
   };

   function getEqualColumns(object) {
      return (
         object.productColumn === object.userColumn ||
         object.productColumn === object.ratingsColumn ||
         object.ratingsColumn === object.userColumn
      );
   }

   const renderTargetSelect = () => {
      return (
         <select
            onChange={(e) => {
               dispatch(setMainAttribute(e.target.value));
               setListData(
                  listData.map((data) => {
                     return { ...data, selected: true };
                  }),
               );
            }}
         >
            {listTarget.map((element) => {
               return (
                  <option key={element.Id} value={element.name}>
                     {element.name}
                  </option>
               );
            })}
         </select>
      );
   };

   const quantityOfColumnsSelected = listData.filter((data) => data.selected).length;
   const hasEnoughColumns = tableData.length > 1 && quantityOfColumnsSelected > 1;
   const isAlsAlgorithm = nameAlgorithm === "ALS";
   const hasNoEqualColumns = !equalColumns && tableData.length > 2 ;
   const hasTargetColumns = listTarget.length > 0;
   const canCreateButton =
      (isAlsAlgorithm && hasNoEqualColumns && tableData.length > 2) ||
      (!isAlsAlgorithm && hasEnoughColumns && hasTargetColumns);
   const showNotEnoughColumnsError = !hasEnoughColumns && !isAlsAlgorithm;
   const showNoTargetColumnError = listTarget.length === 0;

   return (
      <div className="container-choose-algorithm">
         <header>
            <p>{t(`${link}.title`)}</p>
         </header>
         <main>
            <div className="choose-algorithm">{chooseAlgorithm()}</div>
            <div className="model-representation">{modelRepresentation()}</div>
            <div className="container-info-execute">
               <article className="info">
                  <header>
                     <p id="info-title">{nameAlgorithm}</p>
                  </header>
                  <p style={{ textAlign: "justify" }}>{renderInfo()}</p>
                  <button onClick={() => setOpenDialog(true)}>
                     {t("popup.creations.choosealgorithm.learn_more")} <Info />
                  </button>
               </article>
               <div className="choose-main-attribute">
                  <div className="dataset-name">
                     <FileIcon style={{ size: 18 }} />
                     <p>{datasetName}</p>
                  </div>
                  {showTarget && renderTargetSelect()}
                  <div className="input">
                     <header>
                        <p>{t(`${link}.info.input.name`)}</p>
                     </header>
                     <input
                        onChange={(e) => {
                           setNameModel(e.target.value);
                        }}
                        type="text"
                        placeholder={t(`${link}.info.input.placeholder`)}
                     />
                  </div>
                  {canCreateButton && (
                     <Button buttonStyle="btn--primary--solid" buttonSize="btn--auto" onClick={handleClickCreate}>
                        <p>{t(`${link}.button`)}</p>
                     </Button>
                  )}
                  {showNotEnoughColumnsError && (
                     <div id="select-algorithm-error">
                        <p>
                           <WarningOutlined />
                           {t(`${link}.errors.not_enough_columns`)}
                        </p>
                     </div>
                  )}
                  {showNoTargetColumnError && (
                     <div id="select-algorithm-error">
                        <p>
                           <WarningOutlined />
                           {t(`${link}.errors.no_target_column`)}
                        </p>
                     </div>
                  )}
               </div>
            </div>
            <div className="choose-attributes">
               {nameAlgorithm !== "ALS" ? (
                  <>
                     <header>
                        <button className="button-histogram">
                           <p>{t(`${link}.info.select.name`)}</p>
                           <AlgorithmDatasetIcon />
                        </button>
                        <div className="buttons-all-clear">
                           <button
                              onClick={() => {
                                 setListData(
                                    listData.map((data) => {
                                       return { ...data, selected: true };
                                    }),
                                 );
                              }}
                           >
                              <p>{t(`${link}.info.select.button.all`)}</p>
                           </button>
                           <button
                              onClick={() => {
                                 setListData(
                                    listData.map((data) => {
                                       return {
                                          ...data,
                                          selected: mainAttribute === data.name,
                                       };
                                    }),
                                 );
                              }}
                           >
                              <p>{t(`${link}.info.select.button.clear`)}</p>
                           </button>
                        </div>
                     </header>
                     <div style={{ overflow: "auto" }}>
                        {listData.map((element) => {
                           return (
                              <Checkbox
                                 className="attribute"
                                 label={element.name}
                                 onClick={() => handleSelected(element)}
                                 checked={element.selected}
                                 key={element.Id}
                              />
                           );
                        })}
                     </div>
                  </>
               ) : (
                  <>
                     <div className="select-als">
                        <p>{t(`${link}.info.als.inputs.name_1`)}</p>
                        <select
                           id="user-column"
                           defaultValue={0} // Índice padrão para o primeiro select
                           onChange={(e) => {
                              let value = parseInt(e.target.value);
                              let string = JSON.stringify(attributesALS);
                              string = JSON.parse(string);
                              string = {
                                 ...string,
                                 userColumn: value,
                              };
                              setAttributesALS(string);
                              setEqualColumns(getEqualColumns(string));
                           }}
                        >
                           {tableData.map((target, index) => {
                              return (
                                 <option key={target.Id} value={index}>
                                    {target.Nome}
                                 </option>
                              );
                           })}
                        </select>
                     </div>
                     <div className="select-als">
                        <p>{t(`${link}.info.als.inputs.name_2`)}</p>
                        <select
                           defaultValue={1} // Índice padrão diferente para o segundo select
                           onChange={(e) => {
                              let value = parseInt(e.target.value);
                              let string = JSON.stringify(attributesALS);
                              string = JSON.parse(string);
                              string = {
                                 ...string,
                                 productColumn: value,
                              };
                              setAttributesALS(string);
                              setEqualColumns(getEqualColumns(string));
                           }}
                        >
                           {tableData.map((target, index) => {
                              return (
                                 <option key={target.Id} value={index}>
                                    {target.Nome}
                                 </option>
                              );
                           })}
                        </select>
                     </div>
                     <div className="select-als">
                        <p>{t(`${link}.info.als.inputs.name_3`)}</p>
                        <select
                           defaultValue={2} // Índice padrão diferente para o terceiro select
                           onChange={(e) => {
                              let value = parseInt(e.target.value);
                              let string = JSON.stringify(attributesALS);
                              string = JSON.parse(string);
                              string = {
                                 ...string,
                                 ratingsColumn: value,
                              };
                              setAttributesALS(string);
                              setEqualColumns(getEqualColumns(string));
                           }}
                        >
                           {tableData.map((target, index) => {
                              return (
                                 <option key={target.Id} value={index}>
                                    {target.Nome}
                                 </option>
                              );
                           })}
                        </select>
                        {!hasNoEqualColumns  && (
                           <div id="select-algorithm-error">
                              <p>{t(`${link}.info.als.error`)}</p>
                           </div>
                        )}
                     </div>
                  </>
               )}
            </div>
         </main>
         <Popup trigger={histogramPopUp} setTrigger={setHistogramPopUp}>
            <HistogramPopup selectedDataset={datasetName} tableData={tableData} />
         </Popup>
         <HowToDialog open={openDialog} onClose={() => setOpenDialog(false)} algorithmName={nameAlgorithm} />
         {!tutorialDone && <ChooseAlgTour />}
      </div>
   );
}

export default ChooseAlgorithmPopup;
